import React from "react";

const devices = [
    {
        "id": 1,
        "device": "Router",
        "image": require('../img/devices/router.png'),
        "scenario": "Setup your home router with a strong pasword. All the devices in your home will be using this router to access the internet.",
        "options": [
            { "id": 1, "label": "password" },
            { "id": 2, "label": "StroNgPassWord" },
            { "id": 3, "label": "Cisco123" },
            { "id": 4, "label": "@W3eakPa55W0rd", correct: true }
        ],
        "exploit": <div>
            <p>
                Your router was exploited due to a weak password setup by you and now the hacker has access to
                all Wi-Fi connected devices including your office laptop and other smart devices on the network.
            </p>
        </div>,
        "tips": <div>
            <div>Tips for a secure password: </div>
            <ul>
                <li>At least 8 characters—the more characters, the better</li>
                <li>A mixture of both uppercase and lowercase letters</li>
                <li>A mixture of letters and numbers Inclusion of at least one special character, e.g., ! @ # ? </li>
            </ul>
            <p>
                A strong password is hard to guess, but it should be easy for you to remember—a password that has
                to be written down is not strong, no matter how many of the above characteristics are employed.
            </p>
        </div>,
        "link": 'https://www.cisco.com/c/dam/en_us/about/doing_business/trust-center/docs/cisco-top-10-cyber-tips.pdf',
    },
    {
        "id": 2,
        "device": "Laptop",
        "image": require('../img/devices/laptop.png'),
        "scenario": "You have recived an alert from the Infosec team that your cloud account was accessed from an untrusted location. What will be your response to counter such future attacks. ",
        "options": [
            { "id": 1, "label": "Change your password and note it dowm" },
            { "id": 2, "label": "Worry about it if its accessed again"},
            { "id": 3, "label": "Logout from all devices and login only on your laptop"},
            { "id": 4, "label": "Enable multifactor authentication (MFA) for your account", correct: true }
        ],
        "exploit": <div>
            <p>
                The hacker was able to use the password to login into multiple sites without you ever knowing about the breach. After which the password was changed so that you are locked out of your own accounts.
            </p>
        </div>,
        "tips": <div>
            <div>Use two-step verification whenever possible </div>
            <ul>
                
                <li>
                Two-step verification, also known as multifactor authentication (MFA) or two-factor authentication, strengthens security by requiring additional ways to verify your identity beyond your user ID and password. These added layers help guard against phishing, social engineering, and password brute force attacks. 
                </li>
                
            </ul>
            
        </div>,
        "link": 'https://duo.com/product/multi-factor-authentication-mfa/two-factor-authentication-2fa'
    },
    {
        "id": 3,
        "device": "Smartphone",
        "image": require('../img/devices/phone.png'),
        "scenario": "You had a surprise gift delivered to you, but the delivery guy is not able to scan the QR code using his device, so he asks if he can use your phone to scan the QR for delivery confirmation.You will:",
        "options": [
            { "id": 1, "label": "Hand over your device so that he can scan and confirm the delivery" },
            { "id": 2, "label": "You dont hand him the device instead you scan the QR and confirm the delivery" },
            { "id": 3, "label": "Ask him to find help from one of his co-workers to lend him a phone", correct: true },
            { "id": 4, "label": "Lend him your phone so that he can complete the scan but you supervise what he does" }
        ],
        "exploit": <div>
            <p>
                There were two attack vectors for this, the hacker embedded a malicious url in the QR code so it doesn't matter if you gave him your phone or not as soon as someone clicks on that link a malicious RAT is downloaded and all your data can be remotly accessed by the hacker.And if you also gave him your device he can go ahead and download or configure all kinds of malicious scripts.
            </p>
        </div>,
        "tips": <div>
            <div> <b>Don't lend your phone to someone you don’t know—they can do damage even in a short time.</b></div>
            <ul>
                <li>
                    Malicious URLs can be configured on QR codes to automaticaly download scripts and execute, to infect your device or steal data.
                </li>

                <li>
                    Your device in unknown hands even for a short while can lead to exposing password dumps or personal data to malicious actors.
                </li>
                
            </ul>
        </div>,
        "link":'https://www.securitymetrics.com/blog/5-ways-your-mobile-device-can-get-malware'
    },
    {
        "id": 4,
        "device": "Smart Vaccum Cleaner",
        "image": require('../img/devices/vaccum.png'),
        "scenario": "You got a new smart vaccum cleaner with AI features for your home. It comes with an app that you can use to control the robot.While you are installing the app its asking for Camera, Microphone and Admin permissions so that AI features will work perfectly.",
        "options": [
            { "id": 1, "label": "Grant Camera, Microphone permission only as its needed for AI to work" },
            { "id": 2, "label": "Deny all unwated permission requests from the app", correct: true},
            { "id": 3, "label": "Click allow only while using the app option"},
            { "id": 4, "label": "Grant all permissions so that the AI features can work perfectly"}
        ],
        "exploit": <div>
            <p>
                Why does the mobile app for the vaccum cleaner need permission for your phone Camera and Microphone? The AI features can use the sensors on the robbot itself right? Well if you had this question in your mind before granding those permissions you could have been saved from the attack. Now the hacker can spy on you anytime as you have given him the permission to do so.
            </p>
        </div>,
        "tips": <div>
            <div className="mb-1">Regularly audit applications you have installed as privacy settings can change with upgrades</div>
            <ul>
                <li>Some mobile apps may have access to more information than you realize. Before downloading a new app, pay close attention to the permissions it requires.</li>
                <li>Do you really want that app to capture data from your Camera roll, Microphone, Keystrokes?</li>
            </ul>
        </div>,
        "link":  "https://www.cisco.com/c/dam/en_us/about/doing_business/trust-center/docs/cisco-top-10-cyber-tips.pdf"
    },
    {
        "id": 5,
        "device": "Smart Bulb",
        "image": require('../img/devices/bulb.png'),
        "scenario": "Super Saver Sale happening on Amazon on electronics and home appliances and there are these cool smart bulbs with 16 million colors with smartphone control and Bluetooth connectivity just for 50 bucks. Let’s go ahead and: ",
        "options": [
            { "id": 1, "label": "Save a lot of money and get those cool bulbs before sale ends." },
            { "id": 2, "label": "Purchase a different bulb with no offers but from a trusted manufacturer.", correct: true},
            { "id": 3, "label": "Read all reviews and product description and then buy only 2 for  50 bucks. "},
            { "id": 4, "label": "Buy them for your entire house."}
        ],
        "exploit": <div>
            <p>
                Low cost items come with low cost security features, that's right when you made that compromise on
                money you had to sacrifice your home security to a hacker.
            </p>
            <p>
                "Through this exploitation, a threat actor can infiltrate a home or office's computer network
                over-the-air, spreading ransomware or spyware, by using nothing but a laptop and an antenna from
                over 100 meters"
            </p>
        </div>,
        "tips": <div>
            <div>Tips while buying smart devices online - </div>
            <ul>
                <li>
                    Never compromise on security when it comes to technology, read the product documentation carefully
                    and purchase smart devices with good security implementations.
                </li>
                <li>
                    Keep all your Smart devices up-to-date at all times, hackers find new ways to get into
                    your network every minute so be prepared.
                </li>
            </ul>
        </div>,
        "link": "https://thehackernews.com/2020/02/philips-smart-light-bulb-hacking.html"
    },
    {
        "id": 6,
        "device": "Smart Watch",
        "image": require('../img/devices/health_tracker.png'),
        "scenario": "You got a new smart watch with a lot of cool features like Step counter with GPS, Sleep Monitor, Heart rate and so on. The app has yet another cool feature that lets you share your data with the community and friends.",
        "options": [
            { "id": 1, "label": "Share your daily workout records and health data with the community to showcase your fitness to the world"},
            { "id": 2, "label": "Share workout data and health information only on the app community "},
            { "id": 3, "label": "Share achievements but not any personal information to the community", correct: true},
            { "id": 4, "label": "Do not share on app community but use Instagram instead" }
        ],
        "exploit": <div>
            <p>
                The hacker was able to collect your personal data and use it for his own advantage. This kind of data can be sold or be used to provide an overall health portrait of you.
            </p>
        </div>,
        "tips": <div>
            <div>Keep personal information private</div>
            <ul>
                <li>A simple step counter or pedometer is relatively harmless, while some wearable devices can identify more detailed information such as your heart rate or body mass index and much more. In theory the detailed information that fitness trackers collect on millions of users can provide an overall portrait of these individuals and their general health</li>
                <li>Healthcare data is also valuable to hackers. A Trustwave study commissioned in 2017 found a healthcare record for one person cost an average of $250, significantly higher than credit card information, which cost $5.40</li>
                
            </ul>
        </div>,
        "link": "https://www.cisco.com/c/dam/en_us/about/doing_business/trust-center/docs/cisco-top-10-cyber-tips.pdf"
    },
    {
        "id": 7,
        "device": "Desktop",
        "image": require('../img/devices/pc.png'),
        "scenario": "Received a mail from Universal Health Insurance Company, stating that you have been selected for a COVID relief plan for you and your family with 10 Lakhs cover at a premium of only 500/annum per person if you sign up today itself. The links and files for  enrollment are attached. Let's go ahead and: ",
        "options": [
            { "id": 1, "label": "Never mind I have my own insurance policy and not even open it.", correct: true },
            { "id": 2, "label": "Download and read the attached documents and sign up using the link."},
            { "id": 3, "label": "Enroll ASAP before the links expires"},
            { "id": 4, "label": "Reply to the mail and read all documents attached and then enroll. " }
        ],
        "exploit": <div>
            <p>
                You have become the victim of a successful phishing mail scam during COVID times. All your data has
                been compromised and the hacker now has access to your personal computer and connected
                devices and can use it for nefarious purposes.
            </p>
        </div>,
        "tips": <div>
            <div>Tips to look out for Phishing Emails - </div>
            <ul>
                <li>Too Good To Be True information</li>
                <li>Creating a Sense of Urgency</li>
                <li>Bogus Hyperlinks & Attachments</li>
                <li>Unsual Sender</li>
            </ul>
        </div>,
        "link": "https://cisco.sharepoint.com/sites/ThePhishpond"
    },
    {
        "id": 8,
        "device": "Smart Voice Assistant ",
        "image": require('../img/devices/alexa.png'),
        "scenario": "Got a brand new home assistant device to make your home more smart, but now the main confusion is where to place it for better accessibility. Let me just:  ",
        "options": [
            { "id": 1, "label": "Keep it near my home landline for easy access. "},
            { "id": 2, "label": "Keep it in a corner and mute it if I'm not using it.", correct: true },
            { "id": 3, "label": "Mute it and keep it near the windows for better connection" },
            { "id": 4, "label": "Place it in my cozy bedroom and make it do cool stuff always." }
        ],
        "exploit": <div>
            <p>
                Your home assistant was exploited and now the hacker can listen to your private conversation and
                issue commands and execute task on your behalf.The hacker can use two main attacks by using a
                malicious app to listen to you since you have not muted the device or can user a state of the art
                laser based attack to issue commands on your behalf.
            </p>
        </div>,
        "tips": <div>
            <ul>
                <li>Turn off/Mute your home virtual assistant while having private or sensitive conversations.</li>
                <li>Keep it away from other audio sources like landline as voicemails can sometimes confuse your assistant and issue commands.</li>
                <li> Place your device in a secure location away from windows or other openings to protect against laser based attacks.</li>
            </ul>
        </div>,
        "link": "https://www.wired.com/story/lasers-hack-amazon-echo-google-home/"
    },
    {
        "id": 9,
        "device": "Smart TV",
        "image": require('../img/devices/tv.png'),
        "scenario": "You bought a smart tv online a month ago. But now your internet bill is going up, after a month of streaming only OTT content, so to save some money: ",
        "options": [
            { "id": 1, "label": "Turn off all software updates on my Smart TV and save a lot of data"},
            { "id": 2, "label": "Keep updates on but turn off autoplay feature", correct: true },
            { "id": 3, "label": "Turn off updates and view less OTT content" },
            { "id": 4, "label": "Turn off all software updates and turn off autoplay feature" }
        ],
        "exploit": <div>
            <p>
                The hacker exploited various vulnerabilities in your Smart TV’s OS as you turned off the security
                updates to save data.Here you sacrificed security over money and now just like a computer a smart tv
                can be used for all kinds of nefarious purposes by the hacker, to collect data and have full access
                over your networked devices.
            </p>
        </div>,
        "tips": <div>
            <ul>
                <li>Bad actors are constantly looking for opportunities in unpatched software. Keep your software up to date-it’s one of the most effective security tools you have. Better yet, enable automatic updates, so you never have to think about it.</li>
                <li>Purchase Smart TV’s from trusted manufacturers only, which are properly configured to avoid backdoors.</li>
                <li>Secure all physical ports on the device like USB.</li>
            </ul>
        </div>,
        "link": "https://www.welivesecurity.com/2019/08/02/smart-tvs-way-attackers-home/"
    },
    {
        "id": 10,
        "device": "IP Camera",
        "image": require('../img/devices/cctv.png'),
        "scenario": "For maximum security in your house you have bought a new IP camera and installed it and connected it with your pc and phone, the device came with default password and username for better accessibility, Lets go ahead and: ",
        "options": [
            { "id": 1, "label": "Change it to a different username and password.", correct: true },
            { "id": 2, "label": "Write down the default password on a sticky note and put it over your PC. "},
            { "id": 3, "label": "Not write it down instead keep it in a secure password lock app." },
            { "id": 4, "label": "Remember the default creds and not put it down on any electronic device." }
        ],
        "exploit": <div>
            <p>
                You have fallen prey to the most common human error that leads to disastrous consequences and the
                birth of major exploits like Mirai.The default passwords of all devices are available in the public
                domain and now everyone in the internet can access your  cctv camera and use it for all kinds of
                malicious activity, including invading your privacy, logging your daily life or even to make a
                botnet out of all such devices to perform attacks like Mirai.
            </p>
        </div>,
        "tips": <div>
            <ul>
                <li>Your first priority should be changing the default credentials of any device you purchase.</li>
                <li>Keep the device updated with latest patches</li>
                <li>It’s best to isolate your camera from your internet facing router.</li>
            </ul>
        </div>,
        "link": "https://www.csoonline.com/article/2844283/peeping-into-73-000-unsecured-security-cameras-thanks-to-default-passwords.html"
    }
];

export default devices;