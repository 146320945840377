import React from 'react';
import styled from "@emotion/styled";

const ExploitWrapper = styled.div`
    background: rgba(255,255,255,0.75);
    width: 100%;
    min-width: 500px;
    min-height: 50vh;
    max-width: 100vw;
    border-radius: 15px;
    position: relative;
    .row {
        padding: 2vh 2.5vw;
    }
`;

const HeaderSection = styled.div`
    background: rgba(255,255,255,0.6);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    color: black;
    padding: 3vh 2.5vw;
    h5 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px;
    }
`;

const CloseButton = styled.button`
     border: none!important;
     outline: none!important;
     background: transparent!important;
     position: absolute;
     top: 5px;
     right: 5px;
     img {
        width: 32px;
     }
`;

const LearnMoreButton = styled.a`
    color: white!important;
    background: #0d274d;
    padding: 0.75rem 1.5rem;
    text-decoration: none!important;
    border-radius: 5px;
`;

export default ({
    device, image, exploit, tips, link, onClose,
}) => {

    return <ExploitWrapper>
        <CloseButton onClick={onClose}><img alt="close" src="https://img.icons8.com/fluent/2x/close-window.png" /></CloseButton>
        <div>
            <HeaderSection>
                <h5>{device}</h5>
                <p>{exploit}</p>
            </HeaderSection>
            <div className="row mx-0">
                <div className="col-md-8 px-1">
                    <div className="w-100">
                        {tips}
                    </div>
                </div>
                <div className="d-none d-md-block col-md-4 d-flex align-items-center h-100 px-2">
                    <img
                        draggable="false" alt={device}
                        style={{ width: '350x', maxWidth: '100%' }}
                        src={require('../img/hacker.png')}
                    />
                </div>
            </div>
            <div className="p-3 d-flex align-items-center justify-content-center">
                <div className="text-center mb-2">
                    <div className="mb-3">Read more about the attack here -</div>
                    <LearnMoreButton target="_blank" href={link}>Learn More</LearnMoreButton>
                </div>
            </div>
        </div>
    </ExploitWrapper>
}